@import 'variables';

/* Import PrimeNG first before Tailwind CSS */
@import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/primeng.min.css';

/* Import Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Inter, sans-serif !important;
}

input {
  padding: 0.5rem 0.5rem !important;
  border: 1px solid #ced4da;
  border-radius: 8px;
  height: 48px;
}

.text-primary {
  color: $primary-color !important;
}
.text-danger {
  color: $danger-color !important;
}

p-button {
  height: 48px;
  button {
    width: 100%;
    text-align: center;
    display: block;
    height: 100%;
    background: $primary-color;
    border-radius: 8px;
  }
}

p-button.text-button {
  button {
    background: white !important;
    color: $primary-color;
    font-weight: bold;
  }
}

p-multiselect.headers {
  .p-multiselect {
    height: 48px;
    background: $primary-color;
    border-radius: 8px;
  }
  .p-multiselect-label-container {
    align-items: center;
    color: white;
  }
  .p-multiselect-trigger {
    color: white;
  }
  .p-placeholder {
    color: white;
  }
}

p-multiselect.filters {
  .p-multiselect {
    height: 48px;
    border-radius: 8px;
    margin-top: 20px;
    margin-top: 10px;
  }
  .p-multiselect-label-container {
    align-items: center;
  }
}

.p-column-filter-buttonbar {
  display: none;
}

.input-danger {
  border-color: #f44336 !important;
}

.input-error {
  color: red;
}

.primary {
  background: $primary-color !important;
  color: white !important;
}

.otp-box {
  border-radius: 8px !important;
  border: 1px solid $primary-border-color !important;
  margin-right: 20px !important;
}

.toast-container .ngx-toastr {
  min-width: 300px;
  max-width: 500px;
  width: fit-content;
}

.d-flex {
  display: flex !important;
  align-items: center;
}

.terms-conditions-checkbox {
  margin-top: 25px;
  margin-bottom: 25px;
}

p-progressspinner {
  vertical-align: middle;
}

.p-progress-spinner {
  width: 24px;
  height: 24px;
  margin-top: 3px;
}

.p-progress-spinner-circle {
  stroke: white !important;
  stroke-width: 6px !important;
}
app-sidebar {
  a {
    text-decoration: none !important;
  }
  .height-100 {
    height: calc(100vh - 69px) !important;
  }
}

app-header {
  grid-column: 1 / 3;
  z-index: 1000;
  position: relative;
  .p-toolbar {
    border-color: black !important;
    border-radius: 0;
    background: black;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    p {
      color: white;
      font-weight: bold;
    }
    i {
      color: white;
      margin: 0 10px;
      font-size: 1.2rem;
    }
    .avatar {
      border-radius: 50% !important;
      cursor: pointer;
    }
    .toggle-btn {
      cursor: pointer;
      margin-left: 5px;
    }
    .header-icons {
      i {
        cursor: pointer;
      }
    }
  }
}

.main-layout {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.right-form {
  .p-sidebar {
    border-top-left-radius: 24px;
    padding: 20px;
    width: 50% !important;
  }
}

app-right-form-base {
  .w-50 {
    .p-sidebar {
      width: 50% !important;
    }
  }
  .w-25 {
    .p-sidebar {
      width: 25% !important;
    }
  }
  .w-75 {
    .p-sidebar {
      width: 75% !important;
    }
  }
  .w-90 {
    .p-sidebar {
      width: 90% !important;
    }
  }
  .w-100 {
    .p-sidebar {
      width: 100% !important;
    }
  }
}

.p-skeleton {
  height: 3rem !important;
}

app-add-role-form {
  .p-tabview-panels {
    padding: 0px;
    overflow-y: scroll;
    height: 65vh !important;
    padding-right: 25px;
  }
}

p-colorpicker {
  .p-colorpicker-preview {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 5px;
    background: linear-gradient(#f07fd0, #fcf77e, #8ab4f8);
  }
}

.p-highlight {
  .p-tabview-nav-link {
    color: $primary-color !important;
    border-bottom-color: $primary-color !important;
  }
}

.p-inputswitch-checked {
  .p-inputswitch-slider {
    background: $primary-color !important;
  }
}

.p-dropdown,
.p-multiselect {
  width: 100% !important;
  border: 1px solid $dropdown-border-color !important;
  height: 48px;
  align-items: center;
  border-radius: 8px;
}

app-table-action-button {
  p-button {
    button {
      width: auto !important;
      padding: 10px !important;
      display: flex !important;
      margin: 0 2px !important;
      background: none !important;
    }
  }
}

.p-button-primary {
  color: white !important;
  background: $primary-color !important;
  border: 1px solid $primary-border-color !important;
}

.p-datatable-header {
  background: transparent;
  border: none;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}

.p-paginator {
  color: $table-header-color;
  background-color: $table-headerbg-color;
  border-radius: 8px;
  justify-content: flex-end;
}

.p-orderlist-controls {
  display: none;
}

p-inputGroup {
  p-inputGroupAddon {
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100px !important;
  }
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.p-card-content {
  padding: 0px !important;
}

.p-accordion .p-accordion-content {
  padding: 0 !important;
}

.p-accordion-header-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.8px;
  color: #192a3e;
}

.p-accordion-header-link {
  padding: 30px;
  height: 48px;
  border-radius: 8px;
  background-color: $table-headerbg-color;
}

.p-orderlist .p-orderlist-list {
  padding: 0 !important;
}

.p-orderlist .p-orderlist-list-container {
  border: none !important;
}

.p-accordion-content {
  border: none !important;
}

.p-accordion-toggle-icon {
  margin-left: 20px;
  margin-right: 20px;
}

.p-orderlist-list-container ul li {
  padding: 0 !important;
}

.child-list {
  .p-orderlist-list-container ul li {
    margin-left: 20px;
  }
}

.p-orderlist-list {
  min-height: fit-content;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  background-color: $table-headerbg-color;
  border-radius: 8px;
  margin-top: 10px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:first-child {
  margin-top: 0px !important;
}

.p-accordion-toggle-icon {
  position: absolute;
  right: 0;
}

.label-button {
  button {
    width: auto !important;
    height: auto !important;
    display: inherit !important;
    background: transparent !important;
    padding: 0 !important;
    color: $primary-color;
  }
}

app-add-kdeform {
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #8e9aa0;
    padding: 1rem 0;
    font-weight: 400;
    font-size: 16px;
    margin-right: 36px;
  }
  .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link {
    color: #4763e4;
    font-weight: 600;
  }
  .p-tabview .p-tabview-panels {
    padding: 0;
  }
}

app-product-qr-settings-form {
  .p-accordion-tab {
    margin-bottom: 10px !important;
    .p-accordion-content {
      padding: 25px !important;
      margin-top: 10px !important;
      border-radius: 10px;
    }
    .p-accordion-header {
      .p-accordion-header-link {
        background-color: white !important;
        padding: 30px;
      }
    }
  }
}

.sidebar-orderlist {
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    margin-top: 0px !important;
    border-radius: 0;
    // border-bottom: 1px solid black;
  }
}

.list {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
}

.p-sidebar .p-sidebar-content {
  padding-bottom: 0;
}

.selected-filter {
  .p-column-filter-menu-button {
    background: $selected-filter-color !important;
    color: $primary-color;
  }
}

.p-datatable .p-sortable-column .p-icon-wrapper {
  margin-top: 4px !important;
}
