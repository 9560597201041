// src/styles/variables.scss
$danger-color: #d32f2f;
$primary-color: #4763e4;
$primary-border-color: #5c73db;
$dark-grey-text: #4a4848;
$dropdown-border-color: #ced4da;

$table-header-color: #505470;
$table-content-color: #192a3e;
$table-headerbg-color: #f7f8fa;
$table-border-color: #eff0f4;
$selected-filter-color: #a6d5fa80;
